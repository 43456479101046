import React from 'react';

import { LinkBlock } from '~/components/Buttons/Components/LinkBlock';
import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';

import styles from '~/global/footer/Components/FooterSocialLink.module.scss';

export const FooterSocialLink = ({
	imageHeight = 0,
	imageName = '',
	imageWidth = 0,
	linkClassName = '',
	title = '',
	trLinkEventName = '',
	url = '',
}) => {
	return (
		<LinkBlock
			className={`${styles['footer-social-link']} ${linkClassName}`}
			data-tr-link-event-name={trLinkEventName}
			href={url}
			rel="noreferrer"
			target="_blank"
		>
			<span className="tw-sr-only">{title}</span>
			<S7Image
				alt=""
				className={styles['footer-social-link-image']}
				src={`${s7ContentPath}/${imageName}`}
				height={imageHeight + 3}
				width={imageWidth + 3}
			/>
			<S7Image
				alt=""
				className={styles['footer-social-link-image-overlay']}
				src={`${s7ContentPath}/${imageName}`}
				height={imageHeight}
				width={imageWidth}
			/>
		</LinkBlock>
	);
};
