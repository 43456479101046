import React from 'react';

import { footerSocialLinks } from '~/global/footer/footer.constants';
import { FooterSocialLink } from '~/global/footer/Components/FooterSocialLink';
import { FooterSocialBCorp } from '~/global/footer/Components/FooterSocialBCorp';

import styles from '~/global/footer/Components/FooterSocial.module.scss';

export const FooterSocial = () => {
	return (
		<section
			className={styles['footer-social']}
			data-qa="footer-social"
			data-tr-link-event-comp-name="social media"
		>
			<FooterSocialBCorp />
			<ul className={styles['footer-social-links']}>
				{
					footerSocialLinks.map((footerSocialLink, index = 0) => {
						return (
							<li
								className={styles['footer-social-link-container']}
								key={`footer-social-link-${index}`}
							>
								<FooterSocialLink {...footerSocialLink} />
							</li>
						);
					})
				}
			</ul>
		</section>
	);
};
